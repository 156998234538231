<template>
  <div class="datetime-picker">
    <v-icon class="datetime-picker__calendar-icon" v-if="showCalendarIcon">{{
      icons.calendar
    }}</v-icon>
    <datetime
      ref="dateField"
      class="datetime-picker__date"
      v-model="date"
      :type="type"
      :value-zone="valueZone"
      :minute-step="minuteStep"
      @input="selectDate"
    >
      <v-text-field
        :prepend-inner-icon="showCalendarIcon ? icon : ''"
        :value="parsedDate"
        class="datetime-picker__input"
        :class="{ 'datetime-picker__input--has-icon': showCalendarIcon }"
        slot="before"
        readonly
        @click="open"
        v-bind="$attrs"
      />
    </datetime>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Date/Time Picker
 * https://github.com/mariomka/vue-datetime
 * ==================================================================================
 **/
import DATETIME_FORMAT from '@/utils/enums/DatetimeFormat'
import {
  dateFormat,
  convertToDayjs,
  compareWithCurrentTime,
} from '@/utils/date'
import { capitalize } from '@/utils/helpers'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import dayjs from 'dayjs'
import { DAY_OF_WEEKS_OBJECT } from '../../utils/enums/BookingOption'
import { mdiCalendar } from '@mdi/js'

export default {
  name: 'Datepicker',
  mixins: [SnackbarMixin],

  props: {
    value: {
      type: [String, Boolean, Object],
      default: null,
    },

    type: {
      type: String,
      default: 'datetime',
      validator: (value) => ['date', 'time', 'datetime'].includes(value),
    },

    dateFormat: {
      type: String,
      default: DATETIME_FORMAT.dateFormat,
    },

    timeFormat: {
      type: String,
      default: DATETIME_FORMAT.timeFormat,
    },

    lessThan: {
      type: String,
      default: null,
    },

    greaterThan: {
      type: String,
      default: null,
    },

    greaterThanCurent: {
      type: Boolean,
      default: null,
    },

    minuteStep: {
      type: [Number, String],
      default: 1,
    },

    disableDays: {
      type: Array,
      default: () => [],
      validator: function (value) {
        return value.every((item) => typeof item === 'string')
      },
    },

    /**
     * vue-datetime options
     */
    valueZone: {
      type: String,
      default: 'UTC',
    },
    showCalendarIcon: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      parsedDate: null,
      date: null,
      icon: mdiCalendar,
      error: null,
      icons: {
        calendar: 'mdi-calendar',
      },
    }
  },

  computed: {
    typeLabel() {
      switch (this.type) {
        case 'datetime':
          return 'Date & Time'
        default:
          return capitalize(this.type)
      }
    },
  },

  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.init()
      }
    },
  },

  created() {
    this.init()
  },

  methods: {
    init() {
      this.date = this.value
      this.parsedDate = this.getParseDate(this.value)

      this.error = null
    },

    open() {
      if (this.$refs.dateField) {
        this.$refs.dateField.isOpen = true
      }
    },

    selectDate() {
      if (this.validate()) {
        this.$emit('input', this.date)
      } else {
        if (this.date) {
          this.showSnackbar(this.error, false)
        }
        this.$emit('input', this.value)
      }
    },

    validate() {
      this.error = null

      if (this.disableDays.length > 0 && this.date) {
        const numberToFind = dayjs(this.date).day()
        const dayName = Object.keys(DAY_OF_WEEKS_OBJECT).find(
          (key) => DAY_OF_WEEKS_OBJECT[key] === numberToFind
        )
        const containsDay = this.disableDays.includes(dayName)
        if (!containsDay) {
          this.error = 'Invalid Date!'
        }
      }

      if (this.greaterThanCurent && compareWithCurrentTime(this.date)) {
        this.error = `${this.typeLabel} must be greater than current time`
      }

      if (this.lessThan && !convertToDayjs(this.lessThan).isAfter(this.date)) {
        this.error = `${this.typeLabel} must be less than ${this.getParseDate(
          this.lessThan
        )}`
      }

      if (
        this.greaterThan &&
        !convertToDayjs(this.greaterThan).isBefore(this.date)
      ) {
        this.error = `${
          this.typeLabel
        } must be greater than ${this.getParseDate(this.greaterThan)}`
      }

      return !this.error
    },

    getParseDate(date) {
      switch (this.type) {
        case 'date':
          return dateFormat(date, this.dateFormat)
        case 'time':
          return dateFormat(date, this.timeFormat)
        case 'datetime':
        default:
          return dateFormat(date, `${this.dateFormat} ${this.timeFormat}`)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.datetime-picker {
  position: relative;
  ::v-deep .datetime-picker__date {
    .vdatetime-input {
      display: none;
    }
  }

  ::v-deep .datetime-picker__input {
    input {
      cursor: pointer !important;
    }

    &--has-icon input {
      padding-left: 26px;
    }

    input:disabled {
      cursor: not-allowed !important;
    }
  }

  &__calendar-icon {
    position: absolute;
    z-index: 1;
    top: 12px;
    left: 12px;
  }
}
</style>
